import React from "react";
// @ts-ignore
import Img6 from "../assets/img/aisol-img6.png";
// @ts-ignore
import bgimg7 from "../assets/img/ai-bg7.png";
import "../styles/AIPersonalizedAnimation.scss";
// @ts-ignore
import AIcard from "./AICard/AIcard";
// @ts-ignore
import employeeIcon from "../assets/img/why/1.png";
// @ts-ignore
import increaseIcon from "../assets/img/why/2.png";
// @ts-ignore
import retentionIcon from "../assets/img/why/3.png";
// @ts-ignore
import transformIcon from "../assets/img/why/4.png";
// @ts-ignore
import creatingIcon from "../assets/img/why/5.png";
// @ts-ignore
import diversityIcon from "../assets/img/why/6.png";
// @ts-ignore
import drivingIcon from "../assets/img/why/7.png";

const AIPersonalized = () => {
  return (
    <div
      className="section bg-warm"
      style={{
        backgroundImage: `url(${bgimg7})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <h2 className="text-center mb-8" style={{ fontSize: 36 }}>
        Discover how Coachello AI coaching can guide you to success
      </h2>
      <div className="AIcard-cardContainer">
        <div>
          <AIcard
            icon={employeeIcon}
            title="Work Environment"
            text="Optimize Your Workspace Dynamics"
            hovertxt="Embrace a hybrid work environment seamlessly.
Enhance your influence and network within your organization.
Harness collective intelligence for collaborative success.
Master adaptability to change in fast-paced settings."
          />
        </div>
        <div>
          <AIcard
            icon={creatingIcon}
            title="Leadership"
            text="Elevate Your Leadership Journey "
            hovertxt="Cultivate a natural leadership posture.
Sharpen management skills for impactful leadership.
Embrace authenticity in your leadership style.
Develop a manager-coach approach for team empowerment."
          />
        </div>
        <div>
          <AIcard
            icon={drivingIcon}
            title="Quality of Life"
            text="Enhance Personal Fulfillment"
            hovertxt="Discover and pursue personal interests.
Boost motivation for sustained engagement.
Foster positive relationships at work.
Achieve a harmonious work-life balance."
          />
        </div>
        <div>
          <AIcard
            icon={retentionIcon}
            title="Stress and Anxiety"
            text="Build Resilience and Well-being"
            hovertxt="
Manage stress and anxiety effectively.
Improve overall well-being for peak performance.
Cultivate resilience in the face of challenges.
Implement strategies for burnout prevention."
          />
        </div>
        <div>
          <AIcard
            icon={increaseIcon}
            title="Communication"
            text="Master the Art of Interaction"
            hovertxt="
Develop assertiveness for clear communication.
Enhance empathy to connect with others.
Refine listening skills for better understanding.
Navigate conflicts with confidence and tact.
"
          />
        </div>
        <div>
          <AIcard
            icon={transformIcon}
            title="Self-awareness"
            text="Unlock Your Full Potential"
            hovertxt="Identify and align with needs to perform optimally.
Clarify and live by your core values.
Explore strengths for personal growth.
Uncover and address blind spots for holistic development."
          />
        </div>
        <div>
          <AIcard
            icon={diversityIcon}
            title="Career Development"
            text="Navigate Your Career Path with Confidence"
            hovertxt="Smoothly transition to new career opportunities.
Strategize for effective career planning.
Find meaning and fulfillment in your work.
Overcome imposter syndrome and self-doubt."
          />
        </div>
        <div>
          <AIcard
            icon={creatingIcon}
            title="Job Performance"
            text="Excel in Your Professional Role"
            hovertxt="Master public speaking for impactful presentations.Boost productivity for greater achievements.Manage time effectively for a balanced approach.Seek and utilize feedback and recognition for continuous improvement."
          />
        </div>
      </div>
    </div>
  );
};

export default AIPersonalized;
